.bg-animation-0 {
    background:
        linear-gradient(to bottom right, #DDF2F4, #84A6D6, #4382BB, #E4CEE0, #A15D98);
    background-size: 1000% 1000%;
    -webkit-animation: TransitionGradient 60s ease infinite;
    -moz-animation: TransitionGradient 60s ease infinite;
    -o-animation: TransitionGradient 60s ease infinite;
    animation: TransitionGradient 60s ease infinite;
}
.bg-animation-1 {
    background:
        linear-gradient(to bottom left, #DC828F, #F7CE76, #E8D6CF, #8C7386, #9C9359);
    background-size: 1000% 1000%;
    -webkit-animation: TransitionGradient 60s ease infinite;
    -moz-animation: TransitionGradient 60s ease infinite;
    -o-animation: TransitionGradient 60s ease infinite;
    animation: TransitionGradient 60s ease infinite;
}
.bg-animation-2 {
    background:
        linear-gradient(to bottom right, #C6C9D0, #C54B6C, #E5B3BB, #C47482, #D5E4C3);
    background-size: 1000% 1000%;
    -webkit-animation: TransitionGradient 60s ease infinite;
    -moz-animation: TransitionGradient 60s ease infinite;
    -o-animation: TransitionGradient 60s ease infinite;
    animation: TransitionGradient 60s ease infinite;
}
.bg-animation-3 {
    background:
        linear-gradient(to bottom left, #F9968B, #F27348, #26474E, #76CDCD, #2CCED2);
    background-size: 1000% 1000%;
    -webkit-animation: TransitionGradient 60s ease infinite;
    -moz-animation: TransitionGradient 60s ease infinite;
    -o-animation: TransitionGradient 60s ease infinite;
    animation: TransitionGradient 60s ease infinite;
}
.bg-animation-4 {
    background:
        linear-gradient(to bottom right, #B8E0F6, #A4CCE3, #37667E, #DEC4D6, #7B92AA);
    background-size: 1000% 1000%;
    -webkit-animation: TransitionGradient 60s ease infinite;
    -moz-animation: TransitionGradient 60s ease infinite;
    -o-animation: TransitionGradient 60s ease infinite;
    animation: TransitionGradient 60s ease infinite;
}
.bg-animation-5 {
    background:
        linear-gradient(to bottom left, #698396, #A9C8C0, #DBBC8E, #AE8A8C, #7C98AB);
    background-size: 1000% 1000%;
    -webkit-animation: TransitionGradient 60s ease infinite;
    -moz-animation: TransitionGradient 60s ease infinite;
    -o-animation: TransitionGradient 60s ease infinite;
    animation: TransitionGradient 60s ease infinite;
}
.bg-animation-6 {
    background:
        linear-gradient(to bottom right, #46302B, #76504E, #D3CCCA, #A37E7E, #86736C);
    background-size: 1000% 1000%;
    -webkit-animation: TransitionGradient 60s ease infinite;
    -moz-animation: TransitionGradient 60s ease infinite;
    -o-animation: TransitionGradient 60s ease infinite;
    animation: TransitionGradient 60s ease infinite;
}

@-webkit-keyframes TransitionGradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes TransitionGradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-o-keyframes TransitionGradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes TransitionGradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}